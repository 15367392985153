import { DropdownApi } from "@/services";
import { OPEN_G_CODE, SET_G_CODE } from "./constants";
import eventBus from "./eventBus";

export async function getSpecimenGCode(data, specimens) {
  const GCodesPayload = specimens.reduce(
    (acc, curr) => {
      if (curr.cptCodes?.length) {
        acc.billingTransactionCodeIds = [
          ...acc.billingTransactionCodeIds,
          ...curr.cptCodes.map(e => e.id)
        ];
      }
      if (curr.icdCodes?.length) {
        acc.icdCodeIds = [...acc.icdCodeIds, ...curr.icdCodes.map(e => e.id)];
      }
      return acc;
    },
    { billingTransactionCodeIds: [], icdCodeIds: [] }
  );
  const gCodes = await DropdownApi.getMatchingGCodes(GCodesPayload);
  eventBus.$emit(OPEN_G_CODE, { message: data, gCodes });
  const gCode = await new Promise(resolve => {
    eventBus.$once(SET_G_CODE, GCodeId => {
      resolve(GCodeId);
    });
  });
  return gCode;
}
