<template>
  <DxPopup
    v-bind="$attrs"
    :visible="isOpen"
    :drag-enabled="false"
    :show-title="true"
    @shown="handleShow"
    @hidden="handleTearDown"
    :width="350"
    :height="280"
    :showCloseButton="false"
    container=".dx-viewport"
    title="G-Code Alert"
  >
    <template v-slot:content>
      <form
        @submit.prevent="handleSubmit"
        v-shortkey="saveShortkey"
        @shortkey="handleSubmit"
        class="container d-flex flex-column"
      >
        <dx-scroll-view>
          <p>{{ gCodeMessage }}</p>
        </dx-scroll-view>
        <select-input
          ref="input"
          label="G-Codes"
          name="G-Codes"
          id="G-Codes"
          :items="gCodes"
          v-model="selectedGCode"
        />
        <button type="submit" class="mt-1 btn btn-primary align-self-end">Save</button>
      </form>
    </template>
  </DxPopup>
</template>

<script>
import { DxPopup } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { mapState } from "vuex";
import { SET_G_CODE, OPEN_G_CODE } from "@/modules/constants";
import SelectInput from "./common/SelectInput.vue";
import eventBus, { fromBusEvent } from "@/modules/eventBus";
import { filter, tap } from "rxjs/operators";
import { altKey } from "@/modules/helpers";
export default {
  components: {
    DxPopup,
    DxScrollView,
    SelectInput
  },
  name: "GCodePopup",
  data() {
    return {
      selectedGCode: null,
      isOpen: false,
      gCodeMessage: "",
      gCodes: [],
      saveShortkey: altKey("s")
    };
  },
  computed: {
    ...mapState({
      caseDetails: state => state.accessionStore.caseDetails
    })
  },
  subscriptions() {
    return {
      opener$: fromBusEvent(OPEN_G_CODE).pipe(
        filter(() => !this.isOpen),
        tap(({ message, gCodes }) => {
          this.gCodes = gCodes;
          this.gCodeMessage = message;
          this.isOpen = true;
        })
      )
    };
  },
  methods: {
    handleTearDown() {
      this.isOpen = false;

      this.selectedGCode = null;
      this.gCodes = [];
      this.gCodeMessage = "";
      eventBus.$off(SET_G_CODE);
    },
    handleShow() {
      if (this.$refs.input?.focus) {
        this.$refs.input.focus();
      }
    },
    handleSubmit() {
      if (!this.selectedGCode) {
        window.notify("Please select a G-Code.", "warning");
      }
      this.isOpen = false;
      eventBus.$emit(SET_G_CODE, this.selectedGCode);
    },
    handleCancel() {
      this.isOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
