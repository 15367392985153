<template>
  <div>
    <label style="font-weight: bold" :class="labelClass" v-html="label"></label>
    <DxTagBox
      @initialized="init"
      v-model="model"
      :searchEnabled="false"
      :openOnFieldClick="false"
      :inputAttr="macroInputAttr"
      :key="valueLength"
      tag-template="macro"
      :focusStateEnabled="true"
      :onKeyDown="handleKeyDown"
    >
      <template v-slot:tagTemplate="{ data }">
        <div class="dx-tag-content">
          {{ data.displayName }}
          <div class="dx-tag-remove-button" @click="removeMacro(data.id, 'click')"></div>
        </div>
      </template>
    </DxTagBox>
  </div>
</template>

<script>
import DxTagBox from "devextreme-vue/tag-box";
import { MacrosApi } from "@/services";
import { mapState } from "vuex";
export default {
  name: "ResultsMacroTagBox",
  components: {
    DxTagBox
  },
  props: ["label", "value", "labelClass", "interfaceDiagnosis"],
  computed: {
    ...mapState({
      caseDetails: state => state.accessionStore.caseDetails,
      caseHolds: state => state.accessionStore.caseHolds
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        return value;
      }
    },
    valueLength() {
      return this.value?.length ? this.value.length : 0;
    }
  },
  data() {
    return {
      macroInputAttr: {
        disabled: true
      },
      shortkeys: { del: ["del"], backspace: ["backspace"] }
    };
  },
  methods: {
    init({ component }) {
      this.tagBox = component;
    },
    async removeMacro(macroId, eventType, skipClear) {
      if (eventType === "key") {
        if (!this.$el.contains(document.activeElement)) {
          return;
        }
      }
      if (this.valueLength === 1 && !skipClear) {
        this.$emit("clear");
      }
      if (eventType === "key") {
        this.model = this.model.filter(e => e.id !== macroId);
        if (this.model.length) {
          this.$nextTick(() => {
            this.focusMacro();
          });
        }
      }
      /* eslint-disable */
      const macro = await MacrosApi.getMacroDetails(macroId);
      if (macro.interfaceDiagnosis === this.interfaceDiagnosis) {
        if (!this.model.length) {
          return;
        }
        let newInterfaceDiagnosis = "";
        const macroData = await MacrosApi.getMacroDetails(this.model[0].id);
        if (macroData.interfaceDiagnosis) {
          newInterfaceDiagnosis = macroData.interfaceDiagnosis;
        }
        this.$emit("updateInterfaceDiagnosis", newInterfaceDiagnosis);
      }
      if (macro?.holdCodes?.length) {
        for (const holdCode of macro.holdCodes) {
          const targetHold = this.caseHolds.find(hold => hold.tagId === holdCode.id);
          if (targetHold) this.$store.dispatch("accessionStore/removeCaseHold", targetHold);
        }
      }
    },
    focusMacro() {
      this.tagBox.focus();
    },
    handleKeyDown({ event }) {
      const length = this.model.length;
      if (event.key === "Delete" && length) {
        this.removeMacro(this.model[length - 1].id, "key");
      }
      if (event.key === "Backspace" && length) {
        if (length === 1) {
          this.$emit("clear");
          return;
        }
        this.$nextTick(() => {
          this.focusMacro();
          this.focusMacro();
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
