<template>
  <div>
    <div class="d-flex justify-content-between">
      <h2>Tables</h2>
      <AddButton @click="toggleAddingPopup" />
    </div>
    <div v-if="tables.length">
      <div v-for="(table, idx) of tables" v-bind:key="idx" class="border p-2 my-2">
        <div class="d-flex justify-content-between align-items-end border-bottom">
          <div class="d-flex justify-content-start">
            <SelectInput
              class="mr-2"
              :items="fontFamilies"
              v-model="table.fontFamily"
              valueExpr="displayName"
              label="Font"
            />
            <SelectInput
              class="mx-2"
              :items="fontSizes"
              v-model="table.fontSize"
              valueExpr="displayName"
              label="Size"
            />
          </div>
          <div class="d-flex justify-content-between align-items-center mb-1">
            <Checkbox
              class="mx-2"
              v-model="table.showBorder"
              label="Show Border"
              :id="'showBorder' + idx"
              :name="'showBorder' + idx"
            />
            <IconButton
              icon="trash-alt"
              class="text-danger"
              @click="removeTable(idx)"
              v-tooltip="'Click to remove table.'"
            />
          </div>
        </div>
        <div class="mt-2" v-html="getTableHtml(table, idx)" @click="handleTableClick" />
      </div>
    </div>
    <div v-else>No tables.</div>
    <SubmitCancelRow @submit="handleSubmit" @cancel="handleCancel" />
    <modal :status="isAddingTables" @close="toggleAddingPopup">
      <h3>Add Table</h3>
      <SelectInput
        v-focus
        label="Table Type"
        v-model="selectedTableTypeId"
        :items="tableTypes"
        valueExpr="typeId"
      />
      <TextInput v-if="selectedTableTypeId === null" label="Columns" v-model="columnsToAdd" />
      <TextInput label="Rows" v-model="rowsToAdd" />
      <SubmitCancelRow @cancel="toggleAddingPopup" @submit="handleAddTable" />
    </modal>
  </div>
</template>

<script>
import Modal from "./common/Modal.vue";
import TextInput from "./common/TextInput.vue";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";
import AddButton from "./common/AddButton.vue";
import SelectInput from "./common/SelectInput.vue";
import { mapState } from "vuex";
import { SettingsApi } from "@/services";
import Checkbox from "./common/Checkbox.vue";
import IconButton from "./common/IconButton.vue";
import { fontSizes, fontWhiteList } from "@/modules/enums";

export default {
  components: { Modal, TextInput, SubmitCancelRow, AddButton, SelectInput, Checkbox, IconButton },
  data() {
    return {
      tables: [],
      isAddingTables: false,
      rowsToAdd: 1,
      columnsToAdd: 2,
      tableTypes: [],
      selectedTableTypeId: null,
      selectedTableType: {},
      fontSizes: fontSizes.map((e, idx) => {
        return { id: idx + 1, displayName: e };
      }),
      fontFamilies: fontWhiteList.map((e, idx) => {
        return { id: idx + 1, displayName: e };
      })
    };
  },
  mounted() {
    SettingsApi.typeCodesSearch.load({ filter: ["typeId", "=", "122"] }).then(res => {
      this.tableTypes = res.map(e => {
        return {
          typeId: e.id,
          displayName: e.displayName,
          headers: e.flag1.split(",").map(h => {
            return { text: h };
          })
        };
      });
    });
    this.loadTables();
    if (!this.tables.length) {
      this.isAddingTables = true;
    }
  },
  watch: {
    selectedTableTypeId: {
      immediate: true,
      handler(nv) {
        if (!nv) {
          this.selectedTableType = {};
        } else {
          const tableType = this.tableTypes.find(e => e.typeId === nv);
          this.selectedTableType = tableType;
        }
      }
    }
  },
  computed: {
    ...mapState({
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      DefaultEditorFontSize: state => state.labSettings.DefaultEditorFontSize,
      DefaultEditorFontFamily: state => state.labSettings.DefaultEditorFontFamily
    })
  },
  methods: {
    getTableHtml(table, idx) {
      const { headers, rows } = table;
      const border = table.showBorder ? "border: 1px solid;" : "";
      const font = `font-family:${table?.fontFamily || "arial"}; font-size:${
        table?.fontSize || "11pt"
      };`;
      const cellStyle = `${border} height: 1.5rem; min-width: 5rem; padding: .25rem;`;
      const headerHtml = `<tr>${headers
        .map(
          (e, idx) =>
            `<th style='${cellStyle}font-weight:bold;' id='cell-t${idx + 1}hc${idx + 1}'>${
              e.text
            }</th>`
        )
        .join("")}</tr>`;
      let rowsHtml = "";
      for (const [rowIdx, row] of rows.entries()) {
        rowsHtml =
          rowsHtml +
          `<tr>${row
            .map(
              (e, colIdx) =>
                `<td style='${cellStyle}' id='cell-t${idx + 1}r${rowIdx + 1}c${colIdx + 1}'>${
                  e.text
                }</td>`
            )
            .join("")}</tr>`;
      }
      return `<table style='${font}'>${headerHtml}${rowsHtml}</table>`;
    },
    async handleTableClick(event) {
      if (event?.target?.id) {
        const cellElement = document.getElementById(event?.target?.id);
        if (["td", "th"].includes(cellElement?.tagName?.toLowerCase())) {
          const tdPosition = JSON.parse(JSON.stringify(cellElement.getBoundingClientRect()));
          const cssText =
            Object.keys(tdPosition)
              .map(e => `${e}:${tdPosition[e] + "px"};`)
              .join("") + "position:absolute;";
          const inputEl = document.createElement("input");
          inputEl.id = event?.target?.id + "-input";
          inputEl.style.cssText = cssText;
          cellElement.append(inputEl);
          const textValue = cellElement.textContent;
          inputEl.value = textValue;
          inputEl.focus();
          inputEl.onblur = event => {
            cellElement.textContent = event?.target?.value;
            this.updateCellData(cellElement);
            inputEl.remove();
          };
        }
      }
    },
    toggleAddingPopup() {
      this.isAddingTables = !this.isAddingTables;
    },
    handleAddTable() {
      let headers = [];
      let rows = [];
      if (this.selectedTableTypeId) {
        headers = this.selectedTableType.headers;
      } else if (this.columnsToAdd.length) {
        for (let i = 0; i < this.columnsToAdd; i++) {
          headers.push({ text: "Header" + (i + 1) });
        }
      }
      const columnsToAdd = this.selectedTableType?.headers?.length || this.columnsToAdd;
      for (let i = 0; i < this.rowsToAdd; i++) {
        let columnsForRow = [];
        for (let j = 0; j < columnsToAdd; j++) {
          columnsForRow.push({ text: "" });
        }
        rows.push(columnsForRow);
      }
      this.tables.push({
        headers,
        rows,
        showBorder: true,
        tableTypeId: this.selectedTableTypeId,
        fontSize: this.DefaultEditorFontSize || "11pt",
        fontFamily: this.DefaultEditorFontFamily || "arial"
      });
      this.toggleAddingPopup();
    },
    handleSubmit() {
      const payload = this.tables.map((e, idx) => {
        const { specimenTableId, tableTypeId } = e;
        return {
          html: this.getTableHtml(e, idx),
          sortOrder: idx,
          specimenTableId,
          tableTypeId
        };
      });
      this.$emit("submit", payload);
    },
    handleCancel() {
      this.$emit("close");
    },
    loadTables() {
      function getTableData(data) {
        const { html, sortOrder, specimenTableId, tableTypeId } = data;
        let returnData = { sortOrder, specimenTableId, tableTypeId, showBorder: true };
        returnData.headers = [...html.matchAll(/<th ?[^>]+>(?<header>[^<]+)<\/th>/gi)]
          .filter(e => e.length)
          .map(e => {
            return { text: e.groups.header };
          });
        const rowsHtml = [...html.matchAll(/<tr>.*?<\/tr>/gi)];
        returnData.rows = rowsHtml
          .map(([e]) => {
            return [...e.matchAll(/<td.*?>(?<cellText>.*?)<\/td>/gi)].map(r => {
              return { text: r.groups.cellText };
            });
          })
          .filter(e => e.length);
        returnData.showBorder = /border:/.test(html);
        const {
          groups: { fontFamily }
        } = /font-family: ?(?<fontFamily>[\w\s]+);/i.exec(html);
        returnData.fontFamily = fontFamily;
        const {
          groups: { fontSize }
        } = /font-size: ?(?<fontSize>[\d]{1,2}pt);/i.exec(html);
        returnData.fontSize = fontSize;
        return returnData;
      }
      if (this.currentSpecimen?.tables?.length) {
        this.tables = this.currentSpecimen.tables.filter(e => e).map(e => getTableData(e));
      }
    },
    updateCellData({ id, textContent }) {
      if (/hc/i.test(id)) {
        const {
          groups: { tableIndex, columnIndex }
        } = /-t(?<tableIndex>\d+)hc(?<columnIndex>\d+)/i.exec(id);
        const table = this.tables[tableIndex - 1];
        table.headers[columnIndex - 1].text = textContent;
      } else {
        const {
          groups: { tableIndex, rowIndex, columnIndex }
        } = /-t(?<tableIndex>\d+)r(?<rowIndex>\d+)c(?<columnIndex>\d+)/i.exec(id);
        const table = this.tables[tableIndex - 1];
        table.rows[rowIndex - 1][columnIndex - 1].text = textContent;
      }
    },
    removeTable(index) {
      this.tables.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped></style>
